<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="11"
    >
      <features-editable :data="featureData">
      </features-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
  </v-row>
</template>

<script>
import { mdiSendOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { DateTime } from 'luxon'
import FeaturesEditable from './FeaturesEditable.vue'

export default {
  components: {
    FeaturesEditable,
  },
  setup() {
    const featureData = ref({
      featureData: {
        id: null,
        createdAt: DateTime.local().toISODate(),
        updatedAt: '',
        market: '',
        featureName: '',
        featureValue: 0.0,
        isActive: true,
        userRegistered: '',
        userModified: '',
        valid: true,
      },
    })

    return {
      featureData,

      // Icons
      icons: {
        mdiSendOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
